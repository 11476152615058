import React, { Fragment, useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useScrollListener from "../../../hooks/useScrollListener";
import "./style.css";
import CatagoryList from "./catagoriesModal";
import { LayoutContext } from "../index";
import { isAdmin, isSeller } from "../auth/fetchApi";
import { toast } from "react-toastify";
import Divider from "@mui/material/Divider";
import { getLoginedUser } from "./FetchApi";
import { getContents } from "../../admin/content/FetchApi";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Autocomplete, TextField, Stack, Avatar } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import InfoIcon from "@mui/icons-material/Info";
import { isMobile } from "react-device-detect";
import logo from "../../../logo/Logo.png";
import { progress } from "react-dom-factories";
import { Link } from "react-router-dom";
const Navber = (props) => {
  const history = useNavigate();
  const location = useLocation();

  const { data, dispatch } = useContext(LayoutContext);

  const isLogin = localStorage.getItem("jwt");

  const navberToggleOpen = () =>
    data.navberHamburger
      ? dispatch({ type: "hamburgerToggle", payload: false })
      : dispatch({ type: "hamburgerToggle", payload: true });

  const loginModalOpen = () =>
    data.loginSignupModal
      ? dispatch({ type: "loginSignupModalToggle", payload: false })
      : dispatch({ type: "loginSignupModalToggle", payload: true });

  const cartModalOpen = () =>
    data.cartModal
      ? dispatch({ type: "cartModalToggle", payload: false })
      : dispatch({ type: "cartModalToggle", payload: true });
  useEffect(() => {
    loginedUser();
    if (!isLogin && location.pathname === "/") {
      setTimeout(() => {
        if (!isLogin) loginModalOpen();
      }, 90000);
    }
  }, []);
  const [user, setUser] = useState({});
  const loginedUser = async () => {
    if (isLogin) {
      let response = await getLoginedUser();
      setUser(response);
    }
  };
  // const [about, setAbout] = useState("");

  // const fetchContents = async () => {
  //   let response = await getContents();

  //   if (response) {
  //     setAbout(
  //       response.reduce((a, r) => {
  //         if (r.content_type === "about") {
  //           return (a = r.url);
  //         }
  //       }, "")
  //     );
  //   }
  // };
  // useEffect(() => {
  //   fetchContents();
  // }, []);
  const Logout = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("cart");
    localStorage.removeItem("wishList");
    localStorage.clear();
    dispatch({ type: "logedinUser", payload: false });
    toast.success("Logout Successful!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    history("/");
  };
  const [catagoryListModal, setcatagoryListModal] = useState(false);

  const [navClassList, setNavClassList] = useState("");
  const scroll = useScrollListener();

  // update classList of nav on scroll
  useEffect(() => {
    if (scroll.y > 150 && scroll.y - scroll.lastY > 0 && isMobile) {
      setNavClassList("-translate-y-full");
    } else {
      setNavClassList("");
    }
  }, [scroll.y, scroll.lastY]);

  window.onscroll = function () {
    updateProgressBar();
  };

  const progressBar1 = document.querySelector(".progress-bar");

  function updateProgressBar() {
    // console.log("first");
    const progressBar = document.getElementById("proBar");
    // console.log("progressBar>>>>>>>>", progressBar, "bar1", progressBar1);
    const totalHeight = document.body.scrollHeight - window.innerHeight;
    const scrolledHeight = window.scrollY;
    const scrollProgress = (scrolledHeight / totalHeight) * 100;
    progressBar.style.width = scrollProgress + "%";
    progressBar.style.width = scrollProgress + "%";
    progressBar1.style.width = `${scrollProgress}%`;
  }

  return (
    <Fragment>
      {/* Navber Section */}
      <nav
        className={`sticky transform transition duration-150 ease-in-out top-0 w-full z-10  bg-gray-200 ${navClassList}`}
      >
        <div className="p-3 md:mx-12 md:p-3 flex justify-between">
          <div
            onClick={(e) => history("/")}
            style={{ letterSpacing: "0.70rem" }}
            className="hidden lg:block flex items-left col-span-1 text-center text-gray-800 font-bold tracking-widest uppercase text-2xl cursor-pointer"
          >
            <img className="h-12" alt="logo" src={logo}></img>
          </div>
          <div className="hidden navmenus col-span-2 flex text-gray-600 mt-1 text-center flex items-center space-x-2">
            <Link
              to="/"
              // className="hover: px-3 py-3 rounded-lg font-light tracking-widest hover:text-blue-400 cursor-pointer"
              className="no-underline px-3 py-3 rounded-lg font-light tracking-widest cursor-pointer transition-all duration-300 ease-in-out bg-gray-200 text-gray-800 hover:bg-blue-600 hover:text-white  hover:shadow-md"
              // onClick={(e) => history("/")}
            >
              Home
            </Link>
            <span
              // to="/shop"
              // className="shop relative hover: py-3 rounded-lg font-light tracking-widest hover:text-blue-400 cursor-pointer"
              className=" no-underline shop px-3 py-3 rounded-lg font-light tracking-widest cursor-pointer transition-all duration-300 ease-in-out bg-gray-200 text-gray-800 hover:bg-blue-600 hover:text-white  hover:shadow-md"
            >
              <span className="m-3">
                <Link
                  to="/shop"
                  className="no-underline font-light text-gray-800 hover:text-white"
                >
                  Shop{" "}
                  <span>
                    <i class="fas fa-caret-down"></i>
                  </span>
                </Link>
              </span>
              <CatagoryList></CatagoryList>
            </span>

            <Link
              to="/shop/diamond-watches"
              // className="hover: px-3 py-3 rounded-lg font-light tracking-widest hover:text-blue-400 cursor-pointer"
              className="no-underline px-3 py-3 rounded-lg font-light tracking-widest cursor-pointer transition-all duration-300 ease-in-out bg-gray-200 text-gray-800 hover:bg-blue-600 hover:text-white  hover:shadow-md"
              // onClick={(e) => history(`/shop/diamond-watches`)}
            >
              Diamond Watches
            </Link>
            {/* <span
              className="hover:bg-gray-400 px-3 py-3 rounded-lg font-light tracking-widest hover:text-gray-800 cursor-pointer"
              onClick={(e) => history("/blog")}
            >
              Blog
            </span> */}
            {/* <span
              className="hover:bg-gray-400 px-3 py-3 rounded-lg font-light tracking-widest hover:text-gray-800 cursor-pointer"
              onClick={(e) => history(`/contents/${about}`)}
            >
              About us
            </span> */}
            <Link
              to="/customize-ring"
              // className="hover: px-3 py-3 rounded-lg font-light tracking-widest hover:text-blue-400 cursor-pointer"
              className="no-underline px-3 py-3 rounded-lg font-light tracking-widest cursor-pointer transition-all duration-300 ease-in-out bg-gray-200 text-gray-800 hover:bg-blue-600 hover:text-white  hover:shadow-md"
              // onClick={(e) => history("/customize-ring")}
            >
              Customize Ring
            </Link>
          </div>

          <div className="col-span-2 lg:hidden flex justify-items-stretch	 items-center">
            <svg
              onClick={(e) => navberToggleOpen()}
              className="col-span-1 lg:hidden w-8 h-8 cursor-pointer text-gray-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <Link
              to="/"
              // onClick={(e) => history("/")}
              style={{ letterSpacing: "0.10rem" }}
              className="no-underline flex items-left text-center font-bold uppercase text-gray-800 text-2xl cursor-pointer px-2 text-center"
            >
              <img className="h-8" src={logo}></img>
            </Link>
          </div>

          <div className="flex items-right col-span-2 lg:col-span-1 flex justify-end">
            {/*  WishList Page Button */}
            {/* <div
              className="hidden md:block hover:bg-gray-300 rounded-lg px-2 py-2 cursor-pointer"
              title="Search"
            >
              <input
                type="text"
                placeholder="Search..."
                className="focus:outline-none px-2 py-2 rounded-lg"
              ></input>
            </div> */}
            <div
              // hover:bg-gray-400
              className="hidden md:block   rounded-lg px-2 py-2 cursor-pointer"
              title="Search"
              onClick={() => {
                props.setOpen(!props.open);
              }}
            >
              <svg
                className="cursor-pointer w-8 h-8 text-gray-600 hover:text-gray-800"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            {/* <div
              onClick={(e) => history("/wish-list")}
              className=" rounded-lg px-2 py-2 cursor-pointer"
              title="Wishlist"
              // hover:bg-gray-400
            >
              <svg
                className={`${
                  location.pathname === "/wish-list"
                    ? "fill-current text-gray-800"
                    : "hover:text-gray-800"
                } w-8 h-8 text-gray-600 cursor-pointer`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                />
              </svg>
            </div> */}

            {localStorage.getItem("jwt") ? (
              <Fragment>
                <div
                  className="userDropdownBtn hover:bg-gray-200 px-2 py-2 rounded-lg relative"
                  title="Logout"
                >
                  <Avatar
                    alt={user.name}
                    sx={{ width: 32, height: 32 }}
                    className="cursor-pointer "
                    src={user.userImage}
                  />
                  {/* <svg
                    className="cursor-pointer w-8 h-8 text-gray-600 hover:text-gray-800"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg> */}
                  <div className="userDropdown absolute right-0 mt-1 bg-gray-200 rounded">
                    {!isAdmin() ? (
                      <Fragment>
                        <li className="flex flex-col text-gray-700 w-48 shadow-lg">
                          <span
                            onClick={(e) => history("/user/orders")}
                            className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </span>
                            <span>My Orders</span>
                          </span>
                          {isSeller() ? (
                            <span
                              onClick={(e) =>
                                window.open("https://b2b.hnmdesigns.com")
                              }
                              className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                            >
                              <span>
                                <svg
                                  className="w-6 h-6"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                  />
                                </svg>
                              </span>
                              <span>B2B Dashboard</span>
                            </span>
                          ) : null}
                          <span
                            onClick={(e) => history("/user/profile")}
                            className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                />
                              </svg>
                            </span>
                            <span>My Account</span>
                          </span>
                          <span
                            onClick={(e) => history("/wish-list")}
                            className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                                />
                              </svg>
                            </span>
                            <span>My Wishlist</span>
                          </span>
                          <span
                            onClick={(e) => history("/user/setting")}
                            className="flex space-x-1 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </span>
                            <span>Setting</span>
                          </span>
                          <span
                            onClick={(e) => Logout()}
                            className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                />
                              </svg>
                            </span>
                            <span>Logout</span>
                          </span>
                        </li>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <li className="flex flex-col text-gray-700 w-48 shadow-lg">
                          <span
                            onClick={(e) => history("/admin/dashboard")}
                            className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </span>
                            <span>Admin Panel</span>
                          </span>
                          <span
                            onClick={(e) => Logout()}
                            className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                />
                              </svg>
                            </span>
                            <span>Logout</span>
                          </span>
                        </li>
                      </Fragment>
                    )}
                  </div>
                </div>
              </Fragment>
            ) : (
              /* Login Modal Button */
              <div
                onClick={(e) => loginModalOpen()}
                className="cursor-pointer hover:bg-gray-200 px-2 py-2 rounded-lg"
                title="Login"
              >
                <svg
                  className="w-8 h-8 text-gray-600 hover:text-gray-800"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                  />
                </svg>
              </div>
            )}
            {/* Cart Modal Button */}
            <div
              onClick={(e) => cartModalOpen()}
              className="hover:bg-gray-200 px-2 py-2 rounded-lg relative cursor-pointer"
              title="Cart"
            >
              <svg
                className="w-8 h-8 text-gray-600 hover:text-gray-800"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                />
              </svg>
              <span className="absolute top-0 ml-6 mt-1 bg-yellow-700 rounded px-1 text-white text-xs hover:text-gray-200 font-semibold">
                {data.cartProduct !== null ? data.cartProduct.length : 0}
              </span>
            </div>
          </div>
        </div>
      </nav>
      <div className="progress-container">
        <div
          style={{ background: "#0535fa" }}
          className="progress-bar"
          id="proBar"
        ></div>
      </div>
      {/* End Navber Section */}
      <div
        style={{ zIndex: "99999999999" }}
        className={`transform transition duration-700  top-0 ease-in-out fixed bg-black opacity-75  w-full h-full grid grid-cols-1 ${
          !data.navberHamburger && "-translate-x-full"
        }`}
      >
        <div className="p-4">
          <div className="flex justify-end text-white ">
            <svg
              onClick={(e) => navberToggleOpen()}
              className="w-6 h-6 cursor-pointer"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="text-sm text-gray-200 font-bold">Menu</div>
          <Divider />
          <div className="text-white font-semibold">
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={(e) => {
                    history("/");
                    navberToggleOpen();
                  }}
                >
                  <ListItemIcon>
                    <HomeIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={(e) => {
                    history("/catagories");
                    navberToggleOpen();
                  }}
                >
                  <ListItemIcon>
                    <ShoppingBasketIcon color="primary"></ShoppingBasketIcon>
                  </ListItemIcon>
                  <ListItemText primary="Shop" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={(e) => {
                    history(`/contents/about-us`);
                    navberToggleOpen();
                  }}
                >
                  <ListItemIcon>
                    <InfoIcon color="primary"></InfoIcon>
                  </ListItemIcon>
                  <ListItemText primary="About Us" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={(e) => {
                    history("/customize-ring");
                    navberToggleOpen();
                  }}
                >
                  <ListItemIcon>
                    <EditLocationAltIcon color="primary"></EditLocationAltIcon>
                  </ListItemIcon>
                  <ListItemText primary="Custom Ring" />
                </ListItemButton>
              </ListItem>
            </List>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Navber;
